import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout mainClassName="error-page">
    <SEO title="404: Not found" />
    <section>
      <div className="inner">
        <h1 className="text__xl">
          404: <span>Not Found</span>
        </h1>
        <p>You just hit a route that doesn't exist...</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
